import { observable, action, toJS } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
// import Loading from '../utilities/loading'
// import postStore from './PostStore'
import dayjs from 'dayjs'

export class NotificationStore {
  @observable isLoading = false
  @observable isUploading = false

  @observable user = null
  @observable users = []
  @observable staffs = []
  @observable params = {
    perPage: 10,
    page: 1,
    status: '',
    city: '',
    search: '',
    gender: '',
    birth_day: '',
    age_from: '',
    age_to: '',
    booking_club: '',
    booking_date: '',
    post_club: '',
    post_date: '',
  }
  @observable notifications = []
  @observable notificationParams = {
    perPage: 10,
    page: 1,
    status: '',
  }

  @action async getUsers(params = {}, reset) {
    this.isLoading = true
    const query = {
      where: {
        role: { $nin: ['admin', 'host', 'waiter', 'customer_service'] },
      },
    }
    if (!reset) {
      params = {
        ...toJS(this.params),
        ...params,
      }
    }
    if (params.gender) {
      query.where.gender = params.gender
    }
    if (params.city) {
      query.where.city = params.city
    }
    if (params.category) {
      query.where.category = params.category
    }

    const response = await api.getUsers({ query, ...params })
    if (response.ok) {
      this.users = response.data.data
      this.params = {
        ...params,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        total: response.data.total,
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async sendNotification(notification) {
    this.isLoading = true
    const params = toJS(this.params)

    for (const k in params) {
      notification.append(k, params[k])
    }

    const response = await api.sendNotification(notification)

    this.isLoading = false
    if (response.ok) {
      return true
    } else {
      showResponseError(response)
    }
  }

  @action async getScheduleNotifications(params = {}) {
    this.isLoading = true
    const query = {
      sort: { created_at: 'desc' },
    }
    params = {
      ...toJS(this.notificationParams),
      ...params,
    }
    const response = await api.getScheduleNotifications({ query, ...params })
    if (response.ok) {
      this.notifications = response.data.data
      this.notificationParams = {
        ...params,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        total: response.data.total,
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async updateScheduleNotification(id, data) {
    const response = await api.updateScheduleNotification(id, data)
    if (response.ok) {
      await this.getScheduleNotifications()
      return true
    } else {
      if (response.status === 422) {
        showError('Can not update notification, try disable it instead')
      } else {
        showResponseError(response)
      }
      return false
    }
  }
  @action async deleteScheduleNotification(id) {
    const response = await api.deleteScheduleNotification(id)
    if (response.ok) {
      await this.getScheduleNotifications()
    } else {
      if (response.status === 422) {
        showError('Can not delete notification, try disable it instead')
      } else {
        showResponseError(response)
      }
    }
  }
}

const notificationStore = new NotificationStore()
export default notificationStore
