import { observable, action, toJS } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError, getSheetData } from '../utilities/utils'
import routerStore from './RouterStore'
// import Loading from '../utilities/loading'
import postStore from './PostStore'
import { saveAs } from 'file-saver'
import XlsxPopulate from 'xlsx-populate'

export class UserStore {
  @observable isLoading = false
  @observable isCreatingCategory = false
  @observable isUploading = false

  @observable user = null
  @observable users = []
  @observable userCategories = []
  @observable staffs = []
  @observable params = {
    perPage: 10,
    page: 1,
    status: '',
    search: '',
    is_online: '',
  }

  @action async createStaff (data) {
    this.isLoading = true
    const response = await api.createUser(data)
    if (response.ok) {
      await this.getStaffs()
      routerStore.replace(`/staffs/${response.data.data._id}/edit`)
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getStaffs () {
    this.isLoading = true
    const query = {
      where: {
        role: { $in: ['waiter', 'host', 'superHost'] },
      },
      with: ['staffClubs', 'clubs', 'superHostClub'],
    }
    const response = await api.getUsers({ query, perPage: 999 })
    if (response.ok) {
      this.staffs = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getUsers (params = {}) {
    this.isLoading = true
    const query = {
      where: {
        role: { $nin: ['admin', 'host', 'waiter', 'customer_service', 'superHost'] },
      },
      with: ['member'],
    }
    params = {
      ...toJS(this.params),
      ...params,
    }
    if (params.is_online === 'online') {
      query.where.is_online = true
    } else if (params.is_online === 'offline') {
      query.where.is_online = { $ne: true }
    }
    const response = await api.getUsers({ query, ...params })
    if (response.ok) {
      this.users = response.data.data
      this.params = {
        ...params,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        total: response.data.total,
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getUserCategories () {
    const response = await api.getUserCategories()
    if (response.ok) {
      this.userCategories = response.data.data
      return response.data.data
    } else {
      showResponseError(response)
      return []
    }
  }

  @action async downloadUsers () {
    this.isLoading = true
    const query = {
      where: {
        role: { $nin: ['admin', 'host', 'waiter', 'customer_service', 'superHost'] },
      },
    }
    const response = await api.getUsers({ query, perPage: 100000 })
    if (response.ok) {
      const data = response.data.data.map(item => ({
        _id: item._id,
        name: item.name,
        uid: item.uid,
        birth_date: item.birth_date,
        gender: item.gender,
        flag: item.flag,
        referral: item.referral,
        verified: item.verified,
        phone: item.phone,
        social_id: item.social_id,
        created_at: item.created_at,
        updated_at: item.updated_at,
        avatar: item.avatar,
        badge: item.badge,
        is_online: item.is_online,
        point: item.point,
        email: item.email,
        role: item.role,
        tags: item.tags ? item.tags.join(', ') : '',
      }))
      let header = [
        '_id',
        'name',
        'uid',
        'birth_date',
        'gender',
        'flag',
        'referral',
        'verified',
        'phone',
        'social_id',
        'created_at',
        'updated_at',
        'avatar',
        'badge',
        'is_online',
        'point',
        'email',
        'role',
        'tags',
      ]
      XlsxPopulate.fromBlankAsync().then(async workbook => {
        const sheet1 = workbook.sheet(0)
        const sheetData = getSheetData(data, header)
        const totalColumns = sheetData[0].length

        sheet1.cell('A1').value(sheetData)
        const range = sheet1.usedRange()
        const endColumn = String.fromCharCode(64 + totalColumns)
        sheet1.row(1).style('bold', true)
        sheet1.range('A1:' + endColumn + '1').style('fill', 'BFBFBF')
        range.style('border', true)
        return workbook.outputAsync().then(res => {
          saveAs(res, `Users_${new Date().toISOString()}.xlsx`)
        })
      })
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getUser (id, isStarting, isLoading = true) {
    if (isLoading) {
      this.isLoading = true
    }
    if (isStarting) {
      this.user = null
    }
    const response = await api.getUser(id)
    if (isLoading) {
      this.isLoading = false
    }
    if (response.ok) {
      this.user = response.data.data
      return this.user
    } else {
      showResponseError(response)
    }
  }

  @action async blockUser (id) {
    const response = await api.updateUser(id, { status: 'blocked' })
    if (response.ok) {
      this.user = response.data.data
      await this.getUsers()
      await this.getStaffs()
    } else {
      showResponseError(response)
    }
  }

  @action async unblockUser (id) {
    const response = await api.updateUser(id, { status: '' })
    if (response.ok) {
      this.user = response.data.data
      await this.getUsers()
      await this.getStaffs()
    } else {
      showResponseError(response)
    }
  }

  @action async deleteUser (id) {
    const response = await api.deleteUser(id)
    if (response.ok) {
      this.user = null
      await this.getStaffs()
    } else {
      if (response.status === 422) {
        showError('Can not delete user, try disable it instead')
      } else {
        showResponseError(response)
      }
    }
  }

  @action async updateUser (id, data) {
    this.isUpdating = true
    const response = await api.updateUser(id, data)
    this.isUpdating = false
    if (response.ok) {
      this.user = response.data.data
      await this.getUser(id)
    } else {
      showResponseError(response)
    }
  }

  @action async updateStaff (id, data) {
    const response = await api.updateUser(id, data)
    if (response.ok) {
      this.user = response.data.data
      await this.getUsers()
      routerStore.push(`/staffs`)
    } else {
      showResponseError(response)
    }
  }

  @action async uploadLogo (id, file) {
    const formData = new FormData()
    formData.append('logo', file)
    this.isUploading = true
    const response = await api.uploadUserLogo(id, formData)
    if (response.ok) {
      this.user = response.data.data
      await this.getUsers()
    } else {
      showResponseError(response)
    }
    this.isUploading = false
  }

  @action async uploadAvatar (id, file) {
    const formData = new FormData()
    formData.append('image', file)
    this.isUploading = true
    const response = await api.uploadAvatar(id, formData)
    if (response.ok) {
      this.user = response.data.data
      await this.getUsers()
    } else {
      showResponseError(response)
    }
    this.isUploading = false
  }

  @action async uploadMedia (id, { file, day, type }) {
    const formData = new FormData()
    formData.append('media', file)
    formData.append('type', type)
    formData.append('day', day)
    this.isUploading = true
    const response = await api.uploadUserMedia(id, formData)
    if (response.ok) {
      await postStore.getUserMedias(id)
    } else {
      showResponseError(response)
    }
    this.isUploading = false
  }

  @action async inviteMember (data) {
    const response = await api.inviteMember(data)
    if (response.ok) {
      await this.getUsers()
    } else {
      showResponseError(response)
    }
  }
}

const userStore = new UserStore()
export default userStore
