import dayjs from 'dayjs'
import { toast } from 'react-toastify'

export const removeNonNumber = (string = '') => string.replace(/[^\d]/g, '')
export const removeLeadingSpaces = (string = '') => string.replace(/^\s+/g, '')

export function getMessageError (response) {
  if (response) {
    if (response.data && response.data.message) {
      return response.data.message
    } else {
      return response.problem
    }
  } else {
    return 'unknownError'
  }
}

export function getResponseError (response) {
  if (response) {
    if (response.data && response.data.message) {
      return response.data.message.replace(/^[A-Z_]*:/, '')
    } else {
      return response.problem
    }
  } else {
    return 'Unknown error!'
  }
}

export function getDataResponse (response) {
  if (response && response.data) {
    return response.data.data
  } else {
    return null
  }
}

export function getAge (dateString) {
  var birthDay = dayjs(dateString).format('YYYY/MM/DD')
  var today = new Date()
  var birthDate = new Date(birthDay)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export function yearsRevenue (startYear) {
  var currentYear = new Date().getFullYear()
  var years = []
  startYear = startYear || 2018

  while (startYear <= currentYear) {
    years.push(startYear++)
  }

  return years
}

export function showResponseError (response) {
  toast.error(getResponseError(response), {
    autoClose: 3000,
  })
}

export function showError (errorMessage, details) {
  toast.error(errorMessage, {
    autoClose: 3000,
  })
}

export function showMessage (message) {
  toast.success(message, {
    autoClose: 3000,
  })
}

export function getSheetData (data, header) {
  let fields = Object.keys(data[0])
  let sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : ''
    })
  })
  sheetData.unshift(header)
  return sheetData
}

export function move (array, moveIndex, toIndex) {
  /* #move - Moves an array item from one position in an array to another.
     Note: This is a pure function so a new array will be returned, instead
     of altering the array argument.
    Arguments:
    1. array     (String) : Array in which to move an item.         (required)
    2. moveIndex (Object) : The index of the item to move.          (required)
    3. toIndex   (Object) : The index to move item at moveIndex to. (required)
  */
  const itemRemovedArray = [
    ...array.slice(0, moveIndex),
    ...array.slice(moveIndex + 1, array.length),
  ]
  return [
    ...itemRemovedArray.slice(0, toIndex),
    array[moveIndex],
    ...itemRemovedArray.slice(toIndex, itemRemovedArray.length),
  ]
}
