import { observable, action, toJS } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError, getSheetData } from '../utilities/utils'
import Loading from '../utilities/loading'
import postStore from './PostStore'
import routerStore from './RouterStore'
import moment from 'moment'
import { saveAs } from 'file-saver'
import XlsxPopulate from 'xlsx-populate'
export class ReservationStore {
  @observable isLoading = false
  @observable isUploading = false
  @observable isUpdating = false

  @observable reservations = []
  @observable params = {
    perPage: 10,
    page: 1,
    status: '',
    reservation_type: '',
    club_id: '',
  }
  @observable userBookings = []

  @action async getBookings (params = {}) {
    this.isLoading = true
    const query = {
      where: {},
      // where: { status: { $ne: 'cancelled' } },
      with: ['user', 'club', 'cancelledBy'],
      sort: { date: 'desc', created_at: 'desc' },
    }
    params = {
      ...toJS(this.params),
      ...params,
    }
    if (params.status) {
      query.where.status = params.status
    }
    if (params.club_id) {
      query.where.club_id = params.club_id
    }
    if (params.reservation_type) {
      query.where.reservation_type = params.reservation_type
    }
    const response = await api.getReservations({ query, ...params })
    if (response.ok) {
      this.reservations = response.data.data
      this.params = {
        ...params,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        total: response.data.total,
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getUserBookings (userId) {
    this.userBookings = []
    this.isLoading = true
    const query = {
      where: { user_id: userId },
      with: ['user', 'club', 'cancelledBy'],
      sort: { date: 'desc', created_at: 'desc' },
    }
    const response = await api.getReservations({ query })
    if (response.ok) {
      this.userReservations = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async updateReservation (id, data) {
    const response = await api.updateReservation(id, data)
    if (response.ok) {
      routerStore.replace(`/bookings/${id}`)
      return true
    } else {
      showResponseError(response)
      return false
    }
  }

  @action async confirmReservation (id, status) {
    const response = await api.confirmReservation(id, status)
    if (response.ok) {
      return response?.data?.data
    } else {
      showResponseError(response)
      return false
    }
  }

  @action async downloadBookings () {
    this.isLoading = true
    const query = {
      where: {},
      // where: { status: { $ne: 'cancelled' } },
      with: ['user', 'club', 'cancelledBy'],
      sort: { date: 'desc', created_at: 'desc' },
    }
    const response = await api.getReservations({ query, perPage: 100000 })
    if (response.ok) {
      const data = response.data.data.map(reservation => {
        const reservationDate = moment(reservation.date).endOf('day').add(3, 'hours')
        let status = reservation.status
        if (status === 'opening' && reservationDate.diff(moment()) < 0) {
          status = 'No Show'
        }
        // const currencies = {
        //   London: '£',
        //   Paris: '€',
        //   Dubai: 'AED',
        // }
        // const currency = currencies[reservation.club?.city || 'London']

        return {
          _id: reservation._id,
          name: reservation.user?.name,
          type: reservation.reservation_type,
          club: reservation.club?.name,
          men: reservation.gentlemen,
          girl: reservation.ladies,
          spend: reservation.price,
          date: moment(reservation.created_at).format('DD/MM/YYYY HH:mm'),
          for: reservation.date,
          confirmation: reservation.confirmation_status,
          status: status,
          checked_in_time: reservation.checked_in_time,
          cancelled_time: reservation.cancelled_time,
          cancelled_by: reservation.cancelledBy,
        }
      })

      const header = [
        '_id',
        'name',
        'type',
        'club',
        'men',
        'girl',
        'spend',
        'date',
        'for',
        'confirmation',
        'status',
        'checked_in_time',
        'cancelled_time',
        'cancelled_by',
      ]

      XlsxPopulate.fromBlankAsync().then(async workbook => {
        const sheet1 = workbook.sheet(0)
        const sheetData = getSheetData(data, header)
        const totalColumns = sheetData[0].length

        sheet1.cell('A1').value(sheetData)
        const range = sheet1.usedRange()
        const endColumn = String.fromCharCode(64 + totalColumns)
        sheet1.row(1).style('bold', true)
        sheet1.range('A1:' + endColumn + '1').style('fill', 'BFBFBF')
        range.style('border', true)
        return workbook.outputAsync().then(res => {
          saveAs(res, `Bookings_${new Date().toISOString()}.xlsx`)
        })
      })
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }
}

const reservationStore = new ReservationStore()
export default reservationStore
