import { observable, action, toJS } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
import routerStore from './RouterStore'
// import Loading from '../utilities/loading'

export class PromotionStore {
  @observable isLoading = false
  @observable params = {
    perPage: 10,
    page: 1,
    status: '',
  }

  @observable promotions = []

  @action async addPromotions (data) {
    this.isLoading = true
    const response = await api.addPromotions(data)
    if (response.ok) {
      await this.getPromotions()
      routerStore.replace(`/promotions`)
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getPromotions (params) {
    this.isLoading = true
    const query = {
      where: {},
    }
    params = {
      ...toJS(this.params),
      ...params,
    }
    if (params.status) {
      query.where.status = params.status
    }
    const response = await api.getPromotions({ query, ...params })
    if (response.ok) {
      this.promotions = response.data.data
      this.params = {
        ...params,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        total: response.data.total,
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async updatePromotion (id, data) {
    const response = await api.updatePromotion(id, data)
    if (response.ok) {
      await this.getPromotions()
      if (!data.status) {
        routerStore.push(`/promotions`)
      }
    } else {
      showResponseError(response)
    }
  }

  @action async deletePromotion (id) {
    const response = await api.deletePromotion(id)
    if (response.ok) {
      await this.getPromotions()
    } else {
      showResponseError(response)
    }
  }
}

const promotionStore = new PromotionStore()
export default promotionStore
