import { observable, action, toJS } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
import routerStore from './RouterStore'
// import Loading from '../utilities/loading'

export class StoryStore {
  @observable isLoading = false
  @observable isUploading = false

  @observable story = null
  @observable stories = []
  @observable viewers = []
  @observable params = {
    perPage: 10,
    page: 1,
    total: 1,
  }

  @action async uploadFile(file) {
    const formData = new FormData()
    if (file.type.startsWith('image/')) {
      formData.append('image', file)
    } else if (file.type.startsWith('video/')) {
      formData.append('video', file)
    } else {
      return false
    }
    this.isUploading = true
    const response = await api.uploadFiles(formData)
    this.isUploading = false
    if (response.ok) {
      return response?.data?.data
    } else {
      showResponseError(response)
      return null
    }
  }

  @action async getStories(params) {
    this.isLoading = true
    params = {
      ...toJS(this.params),
      ...params,
      query: { with: ['club'] },
    }
    const response = await api.getStories(params)
    this.isLoading = false
    if (response.ok) {
      const data = response.data.data
      this.params = {
        ...params,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        total: response.data.total,
      }
      this.stories = data
      return data
    } else {
      showResponseError(response)
      return []
    }
  }

  @action async createStory(data) {
    this.isLoading = true
    const response = await api.createStory(data)
    if (response.ok) {
      await this.getStories()
      routerStore.replace(`/stories/${response.data.data._id}`)
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async sortStory(data) {
    this.isLoading = true
    const response = await api.sortStory(data)
    if (response.ok) {
      await this.getStories()
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getStory(id) {
    this.isLoading = true
    const response = await api.getStory(id, { query: { with: ['club'] } })
    this.isLoading = false
    if (response.ok) {
      this.story = response.data.data
      return this.story
    } else {
      showResponseError(response)
    }
  }

  @action async updateStory(id, data) {
    const response = await api.updateStory(id, data)
    if (response.ok) {
      this.story = response.data.data
      await this.getStories()
      routerStore.push(`/stories/${this.story._id}`)
    } else {
      showResponseError(response)
    }
  }

  @action async deleteStory(id) {
    const response = await api.deleteStory(id)
    if (response.ok) {
      routerStore.replace('/stories')
      this.story = null
      await this.getStories()
    } else {
      if (response.status === 422) {
        showError('Can not delete story, try disable it instead')
      } else {
        showResponseError(response)
      }
    }
  }

  @action async getViewers(storyId) {
    this.viewers = []
    // this.isLoading = true
    const response = await api.getStoryViewers(storyId)
    // this.isLoading = false
    if (response.ok) {
      this.viewers = response.data.data
    } else {
      showResponseError(response)
    }
  }
}

const storyStore = new StoryStore()
export default storyStore
