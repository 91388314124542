import { create, persist } from 'mobx-persist'
import authStore from './AuthStore'
import router from './RouterStore'
import localForage from 'localforage'
import clubStore from './ClubStore'
import cityStore from './CityStore'
import tutorialStore from './TutorialStore'
import postStore from './PostStore'
import userStore from './UserStore'
import reportStore from './ReportStore'
import summaryStore from './SummaryStore'
import reservationStore from './ReservationStore'
import notificationStore from './NotificationStore'
import pointStore from './PointStore'
import rewardStore from './RewardStore'
import rewardRequestStore from './RewardRequestStore'
import promotionStore from './PromotionStore'
import storyStore from './StoryStore'

const stores = {
  authStore,
  router,
  clubStore,
  cityStore,
  tutorialStore,
  postStore,
  userStore,
  reportStore,
  summaryStore,
  reservationStore,
  notificationStore,
  pointStore,
  rewardStore,
  rewardRequestStore,
  promotionStore,
  storyStore,
}

const hydrate = create({
  storage: localForage,
  jsonify: false,
})

hydrate('auth', authStore).then(() => authStore.checkLogged())

export default stores
